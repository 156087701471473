<template>
  <v-sheet :height="isWidget ? '' : ''" :class="isWidget ? 'cust-widj-calendar cust-calendar' : 'cust-page-calendar cust-calendar elevation-1'">
    <v-toolbar
      flat
      dense
      class="cust-calendar-toolbar"
      height="39"
      v-if="!isWidget"
    >
      <v-btn
        fab
        text
        x-small
        color="grey darken-2"
        @click="prev"
      >
        <v-icon small>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn
        fab
        text
        x-small
        color="grey darken-2"
        @click="next"
      >
        <v-icon small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar || calendarObj.length">
        {{ $moment(calendarObj.lastStart.date).format('DD.MM.YYYY') }} 
        <template v-if="getCalendarPeriod !== 'day'">- {{ $moment(calendarObj.lastEnd.date).format('DD.MM.YYYY') }}</template>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="status-panel">
        <v-chip-group
            :value="getCalendarPeriod"
            active-class="active-panel-item"
            mandatory
            column
        >

          <v-chip
            value="day"
            class="ma-1"
            small
            label
            @click="onPeriodChange('day')"
          >
            <v-icon class="mr-1"> fas fa-calendar-day </v-icon>
            {{ $t('День') }}
            <!--<span v-if="moreOrEqualZero(item.count)"> {{item.count}} </span>-->
          </v-chip>
          <v-chip
            value="week"
            class="ma-1"
            small
            label
            @click="onPeriodChange('week')"
          >
            <v-icon class="mr-1"> fas fa-calendar-week </v-icon>
            {{ $t('Неделя') }}
            <!--<span v-if="moreOrEqualZero(item.count)"> {{item.count}} </span>-->
          </v-chip>
          <v-chip
            value="month"
            class="ma-1"
            small
            label
            @click="onPeriodChange('month')"
          >
            <v-icon class="mr-1"> fas fa-calendar-alt </v-icon>
            {{ $t('Месяц') }}
            <!--<span v-if="moreOrEqualZero(item.count)"> {{item.count}} </span>-->
          </v-chip>
        </v-chip-group>
      </div>
    </v-toolbar>
	
    <v-calendar
		v-model="focus"
		ref="calendar"
		:events="events"
		color="teal"
		:type="isWidget ? 'custom-weekly' : getCalendarPeriod"
		:weekdays="getPeriod"
		:weekday-format="myDayFormat"
		:event-margin-bottom="0"
		@click:event="showEvent"
		@change="loadEvents"
		first-time="07:00"
		:interval-count="15"
		:short-intervals="false"
    >
		<template v-slot:day-label="{ date }">
			<span :class="$moment().format('D') == $moment(date).format('D') ? 'day-number today-number' : 'day-number'">{{ $moment(date).format('D') }}</span>
		</template>

		<template v-slot:event="{ event, eventParsed }">
			<div class="calendar-event-content" v-tooltip.top-center="event.name">
				{{ eventParsed.start.time }} - {{ eventParsed.end.time }}
				/ 
				{{ event.name }}
			</div>
		</template>
    </v-calendar>

    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
      min-width="400px"
      max-width="400px"
    >
      <v-card
        color="grey lighten-4"
        flat
        min-width="400px"
        max-width="400px"
      >
        <v-toolbar
          :color="selectedEvent.color"
          dark
          dense
          flat
        >
          <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="wrapperFormModal">
          <v-card flat>
            <v-card-text>
              <v-form>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="6">
                        <label class="f-label"> {{$t("Дата_совещания")}} </label>
                    </v-col>
                    <v-col  cols="12" sm="12" md="6">
                        <div class="onlyReadData">
                          {{ $moment(selectedEvent.start).format('DD.MM.YYYY') }}
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="6">
                        <label class="f-label"> {{$t("Время_проведения")}} </label>
                    </v-col>
                    <v-col  cols="12" sm="12" md="6">
                        <div class="onlyReadData">
                          {{ $moment(selectedEvent.start).format('HH:mm') }} - {{ $moment(selectedEvent.end).format('HH:mm') }}
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="6">
                        <label class="f-label"> {{$t("Председатель")}} </label>
                    </v-col>
                    <v-col  cols="12" sm="12" md="6">
                      <div class="onlyReadData">
                        <v-workplace-chip :id="selectedEvent.initiatorworkplaceid" :name="selectedEvent.initiatorname" />
                      </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="6">
                        <label class="f-label"> {{$t("Секретарь")}} </label>
                    </v-col>
                    <v-col  cols="12" sm="12" md="6">
                      <div class="onlyReadData">
                        <v-workplace-chip :id="selectedEvent.secretaryworkplaceid" :name="selectedEvent.secretaryname" />
                      </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="6">
                      <label class="f-label"> {{$t("Место")}} </label>
                    </v-col>
                    <v-col  cols="12" sm="12" md="6">
                      <div class="onlyReadData">
                        {{ selectedEvent.place }}
                      </div>
                    </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="event-modal-actions">
          <v-spacer></v-spacer>

            <v-btn 
              color="cyan" 
              text
              depressed
              @click="$eventBus.$emit('open-document', { id: selectedEvent.id, type: 'None.Meeting' })"
            >
              {{$t("Подробнее")}}
          </v-btn>
          
          <v-btn
            color="blue-grey" 
            text
            depressed 
            @click="selectedOpen = false"
          >
            {{$t("Закрыть")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

  </v-sheet>
</template>

<script>
import i18n from '@/i18n';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapMutations, mapGetters } from 'vuex';
import sys from "@/services/system";

export default {
    props: {
        isWidget: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        focus: '',
        calendarObj: { },
        events: [],
        weekDaysLocalize: [i18n.t("Вс"), i18n.t("Пн"), i18n.t("Вт"), i18n.t("Ср"), i18n.t("Чт"), i18n.t("Пт"), i18n.t("Сб")],
        //для открытия события
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
    }),
    computed: {
        ...mapGetters('eventManagement/calendar', { getCalendarPeriod: 'getCurrentPeriod', warningShowed: 'getWarningShowed' }),
        getPeriod () {
          if (this.isWidget)
            return [this.$moment().day(), this.$moment().add(1, 'days').day()];
            switch (this.getCalendarPeriod){
              case 'day':
                return [this.$moment().day()];
              default:
                return [1, 2, 3, 4, 5, 6, 0];
            }
        },
        avaibleStatuses() {
            //Workstatuses
            // 1 - Запланированно
            // 2 - Выполняется
            // 3 - Проведено
            // 0 - Ошибка расчета статуса
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
    },
    methods: {
        ...mapMutations('eventManagement/calendar', { setCalendarPeriod: 'SET_CURRENT_PERIOD', setWarningShowed: 'SET_WARNING_SHOWED' }),
        myDayFormat(day) {
            return this.weekDaysLocalize[day.weekday];
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()));
            }
            else {
                open();
            }

            nativeEvent.stopPropagation()
        },
        //#region НАВИГАЦИЯ - СВЕРХУ СЛЕВА
        prev () {
            this.$refs.calendar.prev();
        },
        next () {
            this.$refs.calendar.next();
        },
        //#endregion
        onPeriodChange(period) {
            this.setCalendarPeriod(period);
        },
        async loadEvents() {
            if (!this.calendarObj.lastStart)
                return;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `/api/eventmanagement/calendarevents?filter=${this.getFilterObject()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.events = [];

            if (response?.data?.payload) {
                response.data.payload.forEach(element => {                    

                    let status = this.avaibleStatuses[this.$helpers.getEventStatus(element.color)];
                    let color = status.style['background-color'];
                    if (this.isWidget && !this.warningShowed){
                      this.$notify.warning(`<b>Запланировано совещание:</b> "${element.name}" <br><b>Время:</b> ${sys.dateFormat(element.start,'DD.MM.YYYY HH:mm')} <br><b>Место:</b> ${element.place}`);
                    }

                    this.events.push({ 
                        id: element.id, 
                        name: element.name, 
                        start: element.start, 
                        end: element.end, 
                        color: color, 
                        initiatorname: element.initiatorname, 
                        initiatorworkplaceid: element.initiatorworkplaceid,
                        secretaryname: element.secretaryname,
                        secretaryworkplaceid: element.secretaryworkplaceid,
                        place: element.place 
                    });
                });
                this.setWarningShowed(true);
            }
        },
        getFilterObject() {
            let filt = null;
            if (this.isWidget){
              filt = {
                FromDate: this.$moment().format('YYYY-MM-DD'),
                ToDate: this.$moment().add(1, 'days').format('YYYY-MM-DD'),
                WorkStatus: 'WithoutPast'
              }
            }
            else {
              filt = {
                FromDate: this.calendarObj.lastStart.date,
                ToDate: this.calendarObj.lastEnd.date
              }
            }
            return JSON.stringify(filt);
        },
    },
    async mounted () {
        this.calendarObj = this.$refs?.calendar;
        await this.loadEvents();
    },
    watch: {
        getCalendarPeriod: {
            handler () {
                this.focus = '';
            },
            deep: true,
        }
    }
}
</script>

<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}

.event-modal-actions{
  padding: 0 16px 16px 16px!important;
}
</style>